import { ReactNode, useEffect } from 'react'
import { useRouter } from 'next/router'
import useAuth from 'hooks/useAuth'
import { PATH_AUTH } from 'routes/paths'
import LoadingScreen from 'components/LoadingScreen'

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode
}

export default function GuestGuard({ children }: Props) {
  const { push, pathname } = useRouter()

  const { isAuthenticated, isInitialized, isOtp } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      push('/')
    }
    if (!isAuthenticated && isOtp) {
      push(
        `${PATH_AUTH.verify}?source=${pathname}&source_action=submit_phone_number`,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isOtp])

  if (isInitialized) {
    return <LoadingScreen />
  }

  return <>{children}</>
}
