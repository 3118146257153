import { forwardRef } from 'react'
import NextLink from 'next/link'
// @mui
import { Box, BoxProps } from '@mui/material'

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean
}

const Logo = forwardRef<any, Props>(
  ({ disabledLink = false, sx }, ref) => {
    const logo = (
      <Box
        ref={ref}
        sx={{ width: 56, height: 56, cursor: 'pointer', ...sx }}
      >
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M40.7818 32.5774L23.3991 42.8319C22.849 43.1565 22.2646 43.3684 21.672 43.4747L21.7087 31.5346L22.39 31.1326L16.2163 20.4308C14.755 17.8978 15.6007 14.6467 18.1051 13.1693C20.6095 11.6919 23.8243 12.5476 25.2856 15.0807L31.4591 25.7825L35.4901 23.4045C37.9945 21.9271 41.2093 22.7829 42.6706 25.3159C44.1318 27.8489 43.2862 31.1 40.7818 32.5774Z"
            fill="#CB1C4F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.3906 13.1654L40.7883 23.3945C43.2949 24.8682 44.1453 28.1181 42.6877 30.6532C41.2302 33.1884 38.0166 34.0489 35.5101 32.5751L31.4756 30.203L25.3178 40.9139C23.8603 43.4491 20.6467 44.3095 18.1401 42.8358C15.6336 41.362 14.7832 38.1122 16.2407 35.577L22.3987 24.8662L18.1123 22.3461C15.6057 20.8723 14.7553 17.6225 16.2129 15.0873C17.6704 12.5522 20.884 11.6917 23.3906 13.1654Z"
            fill="#F87500"
          />
        </svg>
      </Box>
    )

    if (disabledLink) {
      return <>{logo}</>
    }

    return <NextLink href="/">{logo}</NextLink>
  },
)

Logo.displayName = 'Logo'

export default Logo
