import { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
// @mui
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
// type
import { UploadProps } from './type'
//
import Image from '../Image'
import RejectionFiles from './RejectionFiles'
import BlockContent from './BlockContent'
import { sendLog } from 'helpers/log'
import { useFeatureFlags } from 'helpers/feature-flag'

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}))

// ----------------------------------------------------------------------

export default function UploadSingleFile({
  error = false,
  file,
  helperText,
  type,
  sx,
  minDuration = 300,
  minResolution = 360,
  ...other
}: UploadProps) {
  const sendRejectionLog = useFeatureFlags(
    'upload_file_rejection_log',
  )

  const getFilesFromEvent = async (event: any) => {
    const files = event.target?.files || event.dataTransfer?.files
    const promises = []
    const file = files[0]
    const promise = new Promise((resolve) => {
      if (
        file.type === 'video/mp4' ||
        file.type === 'video/quicktime'
      ) {
        const video = document.createElement('video')
        video.preload = 'metadata'
        video.autoplay = true
        video.onloadeddata = () => {
          window.URL.revokeObjectURL(video.src)
          file.width = video.videoWidth
          file.height = video.videoHeight
          file.duration = video.duration
          video.pause()
          video.remove()
          resolve(file)
        }
        video.src = URL.createObjectURL(file)
      } else {
        resolve(file)
      }
    })
    promises.push(promise)
    return await Promise.all(promises)
  }

  const validator = (file: any) => {
    const errors = []

    if (type === 'video') {
      if (file.duration < minDuration)
        errors.push({
          code: 'video-duration-too-short',
        })
      if (file.height < minResolution)
        errors.push({
          code: 'video-resolution-too-small',
        })
    }

    return errors.length < 1 ? null : errors
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    getFilesFromEvent,
    validator,
    useFsAccessApi: false,
    multiple: false,
    ...other,
  })

  const [errors, setErrors] = useState([])

  useEffect(() => {
    if (fileRejections.length > 0) {
      // eslint-disable-next-line no-console
      if (sendRejectionLog) {
        const logErrors = []
        fileRejections.map((rejection) => {
          logErrors.push({
            name: rejection?.file?.name,
            duration: rejection?.file?.duration,
            height: rejection?.file?.height,
            width: rejection?.file?.width,
            size: rejection?.file?.size,
            type: rejection?.file?.type,
            errors: rejection?.errors,
          })
        })
        sendLog(JSON.stringify(logErrors))
      }
    }
    setErrors(fileRejections)
  }, [fileRejections])
  useEffect(() => {
    setErrors([])
  }, [type])

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...(file && {
            padding: '12% 0',
          }),
        }}
      >
        <input {...getInputProps()} />

        <BlockContent type={type} />

        {file && (
          <Image
            alt="file preview"
            src={typeof file === 'string' ? file : file.preview}
            sx={{
              top: 8,
              left: 8,
              borderRadius: 1,
              position: 'absolute',
              width: 'calc(100% - 16px)',
              height: 'calc(100% - 16px)',
            }}
          />
        )}
      </DropZoneStyle>
      {errors.length > 0 && (
        <RejectionFiles
          fileRejections={fileRejections}
          showDetail={false}
          maxSize={other.maxSize}
          minResolution={minResolution}
          minDuration={minDuration}
        />
      )}

      {helperText && helperText}
    </Box>
  )
}
