import EditorToolbarStyle from './EditorToolbarStyle'

// ----------------------------------------------------------------------

// eslint-disable-next-line no-unused-vars
const HEADINGS = [
  'Heading 1',
  'Heading 2',
  'Heading 3',
  'Heading 4',
  'Heading 5',
  'Heading 6',
]

export const formats = [
  'align',
  'background',
  'blockquote',
  'bold',
  'bullet',
  'code',
  'code-block',
  'color',
  'direction',
  'font',
  'formula',
  'header',
  'image',
  'indent',
  'italic',
  'link',
  'list',
  'script',
  'size',
  'strike',
  'table',
  'underline',
  'video',
]

type EditorToolbarProps = {
  id: string
  isSimple?: boolean
  disableFormats?: string[]
}

export default function EditorToolbar({
  id,
  // eslint-disable-next-line no-unused-vars
  isSimple,
  disableFormats,
  ...other
}: EditorToolbarProps) {
  return (
    <EditorToolbarStyle {...other}>
      <div id={id}>
        <div className="ql-formats">
          {!disableFormats?.includes('bold') && (
            <button type="button" className="ql-bold" />
          )}
          {!disableFormats?.includes('italic') && (
            <button type="button" className="ql-italic" />
          )}
          {!disableFormats?.includes('list') && (
            <>
              <button
                type="button"
                className="ql-list"
                value="ordered"
              />
              <button
                type="button"
                className="ql-list"
                value="bullet"
              />
            </>
          )}
        </div>
      </div>
    </EditorToolbarStyle>
  )
}
