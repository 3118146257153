// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { TextField, TextFieldProps } from '@mui/material'

import Iconify from 'components/Iconify'
import { styled } from '@mui/material/styles'
import useLocales from 'hooks/useLocales'

// ----------------------------------------------------------------------

type IProps = {
  name: string
  children: React.ReactNode
}

type Props = IProps & TextFieldProps

const StyledTextField = styled(TextField)({
  '.MuiFormLabel-root.Mui-focused': {
    color: 'unset',
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInputNotchedOutline': {
    borderColor: 'rgba(145, 158, 171, 0.32)',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInputNotchedOutline':
    {
      borderColor: 'rgba(145, 158, 171, 0.32)',
    },
})

export default function RHFSelect({
  name,
  children,
  ...other
}: Props) {
  const { t } = useLocales()
  const { control } = useFormContext()

  const renderErrorText = (message: string | undefined) => {
    if (message)
      return (
        <>
          <Iconify
            icon="bxs:error-circle"
            sx={{ marginBottom: '-2px', marginRight: '5px' }}
          />
          {t(message)}
        </>
      )
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledTextField
          {...field}
          select
          fullWidth
          SelectProps={{ native: true }}
          error={!!error}
          helperText={renderErrorText(error?.message)}
          {...other}
        >
          {children}
        </StyledTextField>
      )}
    />
  )
}
