import { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
// @mui
import { styled, useTheme } from '@mui/material/styles'

import { Box, Stack, Typography } from '@mui/material'
// type
import { UploadProps } from './type'
//
import Image from '../Image'
import RejectionFiles from './RejectionFiles'
import Iconify from 'components/Iconify'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import { useBoundVerificationUserStore } from 'domains/Users/Verification/stores'
import useLocales from 'hooks/useLocales'
import StateImage from 'components/state-image'

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}))

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': { opacity: 0.72 },
}))

export const FileUploadstyle = styled(Stack)({
  width: '304px',
  height: '163px',
  borderRadius: '6px',
  border: '1px dotted #F8F5FF',
  padding: '4px',
})

export const FileUploadWrapper = styled(Stack)({
  width: '100%',
  height: '100%',
  background: '#F8F5FF',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
})

// ----------------------------------------------------------------------

export default function UploadOutlined({
  name = '',
  error = false,
  file,
  helperText,
  caption = '',
  sx,
  ...other
}: UploadProps) {
  const { t } = useLocales()
  const theme = useTheme()
  const { setErrorRejection } = useBoundVerificationUserStore(
    (state) => ({
      setErrorRejection: state.setErrorRejection,
    }),
  )
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    ...other,
  })

  useEffect(() => {
    if (name === 'id_card_photo' || 'npwpPhoto' || 'selfie_photo')
      setErrorRejection({ [name]: fileRejections })
  }, [fileRejections])

  return (
    <Box sx={{ ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(['event_cover_file', 'event_teaser_file'].includes(
            name,
          ) && {
            bgcolor: (theme) => theme.palette.background.lightNeutral,
          }),
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...(file && {
            padding: '12% 0',
          }),
        }}
      >
        <input
          {...getInputProps()}
          capture={name === 'selfie_photo'}
        />
        {[
          'event_cover_file',
          'event_teaser_file',
          'event_venue_map_file',
        ].includes(name) ? (
          <EventBlockContent name={name} t={t} />
        ) : [
            'teaser_file',
            'cover_file',
            'idCardPhoto',
            'npwpPhoto',
            'id_card_photo',
            'selfie_photo',
          ].includes(name) ? (
          <Stack
            spacing={2}
            alignItems="center"
            justifyContent="center"
            direction={{ xs: 'column', md: 'row' }}
            sx={{ width: 1, textAlign: 'left' }}
          >
            {[
              'idCardPhoto',
              'npwpPhoto',
              'id_card_photo',
              'selfie_photo',
            ].includes(name) ? (
              <FileUploadstyle direction={'column'} spacing={2}>
                <FileUploadWrapper
                  sx={
                    name === 'selfie_photo' && {
                      backgroundImage: `url('/assets/selfie_placeholder.png')`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                    }
                  }
                >
                  <AddAPhotoIcon />
                  <Typography variant="caption">{caption}</Typography>
                </FileUploadWrapper>
              </FileUploadstyle>
            ) : (
              <Stack
                sx={{ p: 3 }}
                alignItems="center"
                justifyContent="center"
              >
                <Typography gutterBottom variant="h5">
                  {name === 'teaser_file' &&
                    t('key_upload_your_teaser')}
                  {name === 'cover_file' &&
                    t('key_upload_your_poster')}
                </Typography>

                <Typography
                  gutterBottom
                  variant="body2"
                  sx={{ color: 'text.secondary' }}
                >
                  {t('key_upload_desc')}&nbsp;
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      color: theme.palette.info.main,
                      fontWeight: theme.typography.fontWeightBold,
                    }}
                  >
                    {t('key_upload')}
                  </Typography>
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    fontSize: '12px',
                    color: '#6B6879',
                    marginLeft: '24px',
                    marginBottom: '16px',
                  }}
                >
                  {renderDescription(name, t)}
                </Typography>
              </Stack>
            )}
          </Stack>
        ) : (
          <PlaceholderStyle
            className="placeholder"
            sx={{
              ...(file && {
                opacity: 0,
                color: 'common.white',
                bgcolor: 'grey.900',
                '&:hover': { opacity: 0.72 },
              }),
              ...((isDragReject || error) && {
                bgcolor: 'error.lighter',
              }),
            }}
          >
            <Iconify
              icon="fe:picture-square"
              sx={{ width: 36, height: 36, mb: 1 }}
            />
          </PlaceholderStyle>
        )}
        {file && (
          <Image
            alt="file preview"
            src={typeof file === 'string' ? file : file.preview}
            sx={{
              top: 8,
              left: 8,
              borderRadius: 1,
              position: 'absolute',
              width: 'calc(100% - 16px)',
              height: 'calc(100% - 16px)',
            }}
          />
        )}
      </DropZoneStyle>

      {fileRejections.length > 0 && (
        <RejectionFiles
          fileRejections={fileRejections}
          maxSize={other.maxSize}
          showDetail={false}
        />
      )}

      {helperText && helperText}
    </Box>
  )
}

function renderDescription(name, t) {
  switch (name) {
    case 'cover_file':
    case 'event_cover_file':
    case 'event_venue_map_file':
      return (
        <ul>
          <li>{t('key_upload_photo_desc1')}</li>
          <li>{t('key_upload_photo_desc2')}</li>
        </ul>
      )
    case 'teaser_file':
    case 'event_teaser_file':
      return (
        <ul>
          <li>{t('key_upload_teaser_desc1')}</li>
          <li>{t('key_upload_teaser_desc2')}</li>
          <li>{t('key_upload_teaser_desc3')}</li>
        </ul>
      )
  }
}

function EventBlockContent({ name, t }) {
  const renderTitle = () => {
    switch (name) {
      case 'event_cover_file':
        return t('key_upload_event_poster')
      case 'event_teaser_file':
        return t('key_upload_event_teaser')
      case 'event_venue_map_file':
        return 'Upload foto kamu'
      default:
        return 'Upload file kamu'
    }
  }

  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{
        width: 1,
        textAlign: { xs: 'center', md: 'left' },
      }}
    >
      <StateImage
        type="illustration"
        illustrationName="illustration_upload"
        sx={{ width: 220 }}
      />

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          {renderTitle()}
        </Typography>

        <Typography
          gutterBottom
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >
          {t('key_upload_desc')}&nbsp;
          <Typography
            variant="body2"
            component="span"
            sx={{
              color: '#1890FF',
              fontWeight: '700',
            }}
          >
            {t('key_upload')}
          </Typography>
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#6B6879',
            marginLeft: '24px',
            marginBottom: '16px',
          }}
        >
          {renderDescription(name, t)}
        </Typography>
      </Box>
    </Stack>
  )
}
