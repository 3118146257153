// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { FormHelperText } from '@mui/material'
import Iconify from 'components/Iconify'
// type
import {
  UploadOutlined,
  UploadSingleFile,
  UploadProps,
} from '../upload'

// ----------------------------------------------------------------------

interface Props extends Omit<UploadProps, 'file'> {
  name: string
  type: string
}

const renderErrorText = (message: string | undefined) => {
  if (message)
    return (
      <>
        <Iconify
          icon="bxs:error-circle"
          sx={{ marginBottom: '-2px', marginRight: '5px' }}
        />
        {message}
      </>
    )
}

// ----------------------------------------------------------------------

export function RHFUploadOutlined({ name, accept, ...other }: Props) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value

        return (
          <UploadOutlined
            name={name}
            accept={accept || { 'image/*': [] }}
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error>
                  {renderErrorText(error?.message)}
                </FormHelperText>
              )
            }
            {...other}
          />
        )
      }}
    />
  )
}

// ----------------------------------------------------------------------

export function RHFUploadSingleFile({
  name,
  type,
  accept,
  ...other
}: Props) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value

        return (
          <UploadSingleFile
            accept={accept || { 'image/*': [] }}
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {renderErrorText(error?.message)}
                </FormHelperText>
              )
            }
            type={type}
            {...other}
          />
        )
      }}
    />
  )
}
