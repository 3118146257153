import Head from 'next/head'
import { forwardRef, ReactNode } from 'react'
// @mui
import { Box, BoxProps } from '@mui/material'

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode
  meta?: ReactNode
  title?: string
}

const Page = forwardRef<HTMLDivElement, Props>(
  // eslint-disable-next-line no-unused-vars
  (
    { children, title = 'TipTip Content Hub', meta, ...other },
    ref,
  ) => (
    <>
      <Head>
        <title>{title}</title>
        {meta}
      </Head>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  ),
)

Page.displayName = 'Page'

export default Page
