// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { TextField, TextFieldProps } from '@mui/material'
import Iconify from 'components/Iconify'
import { styled } from '@mui/material/styles'
import useLocales from 'hooks/useLocales'

// ----------------------------------------------------------------------

type IProps = {
  name: string
}

type Props = IProps & TextFieldProps

export const StyledTextField = styled(TextField)({
  '.MuiFormLabel-root.Mui-focused': {
    color: 'unset',
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInputNotchedOutline': {
    borderColor: 'rgba(145, 158, 171, 0.32)',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInputNotchedOutline':
    {
      borderColor: 'rgba(145, 158, 171, 0.32)',
    },
})

export default function RHFTextField({ name, ...other }: Props) {
  const { t } = useLocales()
  const { control } = useFormContext()

  const renderErrorText = (message: string | undefined) => {
    if (message)
      return (
        <>
          <Iconify
            icon="bxs:error-circle"
            sx={{ marginBottom: '-2px', marginRight: '5px' }}
          />
          {t(message)}
        </>
      )
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <StyledTextField
            {...field}
            fullWidth
            value={
              typeof field.value === 'number' && field.value === 0
                ? ''
                : field.value
            }
            error={!!error}
            helperText={renderErrorText(t(error?.message))}
            {...other}
          />
        </>
      )}
    />
  )
}
