import { ReactNode } from 'react'
// form
import { FormProvider as Form, UseFormReturn } from 'react-hook-form'

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode
  methods: UseFormReturn<any>
  onSubmit?: VoidFunction
  disablePressEnter?: boolean
}

export default function FormProvider({
  children,
  onSubmit,
  methods,
  disablePressEnter = true,
}: Props) {
  return (
    <Form {...methods}>
      <form
        onSubmit={onSubmit}
        onKeyDown={(event) => {
          if (!disablePressEnter) {
            event.stopPropagation()
            return null
          } else if (event.code === 'Enter') event.preventDefault()
        }}
      >
        {children}
      </form>
    </Form>
  )
}
