import { SxProps, styled } from '@mui/material/styles'
import appConfig from 'configs/app'

const StyledImage = styled('img')({
  objectFit: 'contain',
  objectPosition: 'center',
  backgroundRepeat: 'no-repeat',
})

type IllustrationName =
  | 'illustration_404'
  | 'illustration_500'
  | 'illustration_booking'
  | 'illustration_checkout'
  | 'illustration_coming_soon'
  | 'illustration_components'
  | 'illustration_dashboard'
  | 'illustration_docs'
  | 'illustration_error'
  | 'illustration_invite'
  | 'illustration_live'
  | 'illustration_login'
  | 'illustration_maintenance'
  | 'illustration_motivation'
  | 'illustration_order_complete'
  | 'illustration_payment_complete'
  | 'illustration_phone_verification'
  | 'illustration_register'
  | 'illustration_seo'
  | 'illustration_suspended'
  | 'illustration_upload'

type ImageType =
  | 'error'
  | 'create-creator-success'
  | 'creator-onboarding'
  | 'external-link'
  | 'followed-creator'
  | 'pending-withdraw'
  | 'success-withdraw'
  | 'no-session-created-yet'
  | 'page-not-found'
  | 'system-error'
  | 'user-alert'
  | 'ticket-not-available'
  | 'empty-data'
  | 'date-time'
  | 'share-content'
  | 'empty-search'
  | 'session-almost-full'
  | 'access-permission'
  | 'no-respond'
  | 'illustration'

interface StateImageProps {
  src?: string
  sx?: SxProps
  alt?: string
  type?: ImageType
  iconRender?: boolean
  iconSize?: 'lg' | 'sm' | 'md'
  illustrationName?: IllustrationName
}

const StateImage = ({
  src = '/img/webp/error.webp',
  sx,
  type,
  alt,
  iconRender,
  iconSize = 'sm',
  illustrationName,
}: StateImageProps) => {
  const ICON_SIZES = {
    lg: 24,
    sm: 16,
  }
  const sourceMapping = () => {
    switch (type) {
      case 'error':
        return '/img/webp/error.webp'
      case 'create-creator-success':
        return `${appConfig.assetBaseUrl}/webp/state_become_creator.webp`
      case 'creator-onboarding':
        return `${appConfig.assetBaseUrl}/webp/state_user_circle.webp`
      case 'external-link':
        return `${appConfig.assetBaseUrl}/webp/state_external_link.webp`
      case 'followed-creator':
        return `${appConfig.assetBaseUrl}/webp/state_user_card.webp`
      case 'pending-withdraw':
        return `${appConfig.assetBaseUrl}/webp/state_waiting_money.webp`
      case 'success-withdraw':
        return `${appConfig.assetBaseUrl}/webp/state_success_get_money.webp`
      case 'no-session-created-yet':
        return `${appConfig.assetBaseUrl}/webp/state_laptop_table.webp`
      case 'page-not-found':
        return `${appConfig.assetBaseUrl}/webp/page_not_found.webp`
      case 'system-error':
        return `${appConfig.assetBaseUrl}/webp/state_system_error.webp`
      case 'user-alert':
        return `${appConfig.assetBaseUrl}/webp/state_user_alert.webp`
      case 'ticket-not-available':
        return `${appConfig.assetBaseUrl}/webp/state_ticket_not_available.webp`
      case 'empty-data':
        return `${appConfig.assetBaseUrl}/webp/empty_data_state.webp`
      case 'date-time':
        return `${appConfig.assetBaseUrl}/webp/state_date_time.webp`
      case 'share-content':
        return `${appConfig.assetBaseUrl}/webp/state_share_content.webp`
      case 'empty-search':
        return `${appConfig.assetBaseUrl}/webp/empty_search.webp`
      case 'session-almost-full':
        return `${appConfig.assetBaseUrl}/webp/session_almost_full.webp`
      case 'access-permission':
        return `${appConfig.assetBaseUrl}/webp/state_acess_permission.webp`
      case 'no-respond':
        return `${appConfig.assetBaseUrl}/webp/state_no_respond.webp`
      default:
        return src
    }
  }

  const renderIllustration = () => {
    return `${appConfig.assetBaseUrl}/webp/illustrations/${illustrationName}.webp`
  }

  return (
    <StyledImage
      src={
        type === 'illustration'
          ? renderIllustration()
          : sourceMapping()
      }
      alt={alt || type}
      sx={{
        width: iconRender ? ICON_SIZES[iconSize] : '240px',
        height: iconRender ? ICON_SIZES[iconSize] : '180px',
        ...sx,
      }}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null
        currentTarget.src = '/img/webp/error.webp'
      }}
    />
  )
}

export default StateImage
