import { useEffect, useState } from 'react'
// @mui
import {
  Typography,
  MenuItem,
  Stack,
  IconButton,
} from '@mui/material'
// hooks
// components
import MenuPopover from 'components/MenuPopover'
import countryLang from 'constants/language'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { getLocalStorage } from 'helpers/local-storage'
import Flag from 'react-country-flag'

export default function MultipleLanguagePopover() {
  const { isReady } = useRouter()
  const { i18n } = useTranslation()

  const DEFAULT = {
    id: 'id',
    label: 'Indonesia',
    flag: `ID`,
  }
  const [currentLang, setcurrentLang] = useState(DEFAULT)

  const langStorage = getLocalStorage('i18nextLng') || 'id'

  const [open, setOpen] = useState<HTMLElement | null>(null)

  function LanguageContent(val) {
    return (
      <Stack
        direction={'row'}
        sx={{
          justifyContent: 'space-around',
          textAlign: 'center',
          gap: 2,
          img: {
            alignSelf: `center`,
          },
        }}
      >
        <Flag
          countryCode={val?.item?.flag}
          svg
          style={{
            width: '28px',
          }}
        />
        <Typography>{val?.item?.label}</Typography>
      </Stack>
    )
  }

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleSelectLanguage = (val) => {
    i18n.changeLanguage(val.id)
    handleClose()
  }

  useEffect(() => {
    if (isReady) {
      const selectedLanguage = countryLang.find(
        (language) => language.id === langStorage,
      )
      setcurrentLang(selectedLanguage)
    }
  }, [isReady, langStorage])
  return (
    <>
      <IconButton id="b-common-change-language" onClick={handleOpen}>
        <Flag
          countryCode={currentLang?.flag}
          svg
          style={{
            width: '28px',
          }}
        />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
      >
        {countryLang.map((item, _index) => (
          <MenuItem
            id={`s-item-language-${item.id}`}
            key={`language-${item.id}`}
            onClick={() => {
              handleSelectLanguage(item)
            }}
          >
            <LanguageContent item={item} />
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  )
}
