import NextLink from 'next/link'
// @mui
import { styled } from '@mui/material/styles'
import {
  Box,
  Card,
  Stack,
  Link,
  Container,
  Typography,
} from '@mui/material'
// routes
import { PATH_AUTH } from 'routes/paths'
// hooks
import useResponsive from 'hooks/useResponsive'
// guards
import GuestGuard from '~/guards/GuestGuard'
// components
import Page from 'components/Page'
import Logo from 'components/Logo'
import Image from 'components/Image'
// sections
import { LoginForm } from 'domains/Users/Auth/Login'
import useLocales from 'hooks/useLocales'
import LoginSocial from 'domains/Users/Auth/Login/LoginSocial'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import trackEvent from '~/trackers'
import { useAuthStore } from 'domains/Users/Auth/store'
import MultipleLanguagePopover from '~/layouts/dashboard/header/MultipleLanguagePopover'
import appConfig from 'configs/app'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

export default function Login() {
  const { query, pathname, isReady } = useRouter()
  const trackerData = { query, pathname }
  const { t } = useLocales()

  const smUp = useResponsive('up', 'sm')
  const mdUp = useResponsive('up', 'md')

  const { authTab } = useAuthStore((state) => ({
    authTab: state.authTab,
  }))

  const handleRedirectRegister = () => {
    trackEvent.auth('click_sign_up', {
      ...trackerData,
      currentTab: authTab,
    })
  }

  useEffect(() => {
    if (isReady) {
      trackEvent.auth('visit_sign_in', {
        ...trackerData,
        currentTab: authTab,
      })
    }
  }, [isReady])

  return (
    <GuestGuard>
      <Page title="Login">
        <RootStyle>
          <HeaderStyle>
            <Logo />
            <Stack
              direction={'row'}
              sx={{ alignItems: 'center', gap: 2, mt: { md: -2 } }}
            >
              <MultipleLanguagePopover />
              {smUp && (
                <Typography variant="body2">
                  {t('key_dont_have_an_account_yet')} {''}
                  <NextLink href={PATH_AUTH.register} passHref>
                    <Link
                      variant="subtitle2"
                      onClick={handleRedirectRegister}
                    >
                      {t('key_register_now')}
                    </Link>
                  </NextLink>
                </Typography>
              )}
            </Stack>
          </HeaderStyle>

          {mdUp && (
            <SectionStyle>
              <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                {t('key_hi_welcome')}
              </Typography>
              <Image
                visibleByDefault
                disabledEffect
                src={`${appConfig.assetBaseUrl}/webp/illustrations/illustration_login.webp`}
                alt="login"
              />
            </SectionStyle>
          )}

          <Container maxWidth="sm">
            <ContentStyle>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ mb: 5 }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    {t('key_sign_in_to_tiptip')}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>
                    {t('key_please_login_to_your_tiptip')}
                  </Typography>
                </Box>
              </Stack>

              <LoginForm />

              {!smUp && (
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ mt: 3 }}
                >
                  {t('key_dont_have_an_account_yet')}{' '}
                  <NextLink href={PATH_AUTH.register} passHref>
                    <Link variant="subtitle2">
                      {t('key_register_now')}
                    </Link>
                  </NextLink>
                </Typography>
              )}

              <LoginSocial />
            </ContentStyle>
          </Container>
        </RootStyle>
      </Page>
    </GuestGuard>
  )
}
