import appConfig from 'configs/app'
// @mui
import { Box, Typography, Stack } from '@mui/material'

import useLocales from 'hooks/useLocales'
import StateImage from 'components/state-image'

// ----------------------------------------------------------------------

interface IBlockContentProps {
  type: string
}

export default function BlockContent(props: IBlockContentProps) {
  const { t } = useLocales()
  const renderDescription = () => {
    const descriptionText = () => {
      switch (props.type) {
        case 'document':
          return (
            <ul>
              <li>{t('key_max_file_size_128mb')}</li>
              <li>{t('key_pdf_file_format')}</li>
            </ul>
          )
        case 'asset':
          return (
            <ul>
              <li>{t('key_max_file_size_500mb')}</li>
              <li>
                {t(
                  'key_file_formats_rar_zip_xlsx_docx_psd_or_other_formats_see',
                )}{' '}
                <a
                  href={`${appConfig.supportCenterUrl}solutions/articles/72000575312`}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                  rel="noreferrer"
                >
                  {t('key_here')}
                </a>
              </li>
            </ul>
          )
        case 'video':
        default:
          return (
            <ul>
              <li>{t('key_upload_video_desc1')}</li>
              <li>{t('key_upload_video_desc2')}</li>
            </ul>
          )
      }
    }
    return (
      <Box sx={{ width: 1, textAlign: 'left' }}>
        {descriptionText()}
      </Box>
    )
  }

  const renderTitle = () => {
    switch (props.type) {
      case 'document':
        return t('key_upload_your_documents')
      case 'asset':
        return t('key_upload_your_file')
      case 'video':
      default:
        return t('key_upload_video_desc')
    }
  }

  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <StateImage
        type="illustration"
        illustrationName="illustration_upload"
        sx={{ width: 220 }}
      />

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          {renderTitle()}
        </Typography>

        <Typography
          gutterBottom
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >
          {t('key_upload_desc')}&nbsp;
          <Typography
            variant="body2"
            component="span"
            sx={{
              color: '#1890FF',
              fontWeight: '700',
            }}
          >
            {t('key_upload')}
          </Typography>
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#6B6879',
            marginLeft: '24px',
            marginBottom: '16px',
          }}
        >
          {renderDescription()}
        </Typography>
      </Box>
    </Stack>
  )
}
