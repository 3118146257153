import { useQuery } from '@tanstack/react-query'
import { getSupportedCountries } from '~/services/user'
import { useSupportedCountryStore } from '../../store'

export const useSupportedCountry = () => {
  const { setSupportedCountry, setError } = useSupportedCountryStore(
    (state) => ({
      setSupportedCountry: state.setSupportedCountry,
      setError: state.setError,
    }),
  )
  return useQuery({
    queryKey: ['supported_country'],
    queryFn: async () => {
      try {
        const response = await getSupportedCountries()
        return response?.data?.data
      } catch (error) {
        throw error
      }
    },
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setSupportedCountry(data)
    },
    onError: (err) => setError(err),
  })
}
