import { FileRejection } from 'react-dropzone'
// @mui
import { alpha } from '@mui/material/styles'
import { Box, Paper, Typography } from '@mui/material'
// utils
import { fData } from '../../utils/formatNumber'
import getFileData from '../../utils/getFileData'
import Iconify from 'components/Iconify'
import { formatBytes } from 'helpers/formats/string'
import useLocales from 'hooks/useLocales'

// ----------------------------------------------------------------------

type Props = {
  fileRejections: FileRejection[]
  showDetail: boolean
  maxSize: number | undefined
  minResolution?: number | undefined
  minDuration?: number | undefined
}

export default function RejectionFiles({
  fileRejections,
  showDetail,
  maxSize = 5242880,
  minResolution,
  minDuration,
}: Props) {
  const { t } = useLocales()
  const renderErrorMessage = (code: string) => {
    const formattedMinDuration =
      minDuration && minDuration >= 60 ? minDuration / 60 : 5
    switch (code) {
      case 'file-too-large':
        return t('key_upload_failed_file_size_exceeds_max', {
          max: formatBytes(maxSize),
        })
      case 'video-resolution-too-small':
        return t(
          'key_upload_failed_please_upload_video_with_minimum_min_resolution',
          {
            min: minResolution,
          },
        )
      case 'video-duration-too-short':
        return t(
          'key_upload_failed_please_upload_a_video_with_a_minimum_duration_of_min_minutes',
          {
            min: formattedMinDuration,
          },
        )
      case 'file-invalid-type':
      default:
        return t('key_upload_error1')
    }
  }

  const renderErrors = () => {
    return fileRejections.map(({ errors }, index) => {
      if (errors.length < 2)
        return errors.map((error, errorIndex) => (
          <span
            style={{ color: '#7A0C2E', fontSize: '14px' }}
            key={errorIndex}
          >
            {renderErrorMessage(error.code)}
          </span>
        ))
      else if (errors.length >= 2) {
        return (
          <ul style={{ marginLeft: '16px' }} key={index}>
            {errors.map((error, listIndex) => (
              <li
                style={{ color: '#7A0C2E', fontSize: '14px' }}
                key={`${index}-${listIndex}`}
              >
                {renderErrorMessage(error.code)}
              </li>
            ))}
          </ul>
        )
      }
    })
  }

  if (showDetail)
    return (
      <Paper
        variant="outlined"
        sx={{
          py: 1,
          px: 2,
          mt: 3,
          borderColor: 'error.light',
          bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
        }}
      >
        {fileRejections.map(({ file, errors }) => {
          const { path, size } = getFileData(file)

          return (
            <Box key={path} sx={{ my: 1 }}>
              <Typography variant="subtitle2" noWrap>
                {path} - {size ? fData(size) : ''}
              </Typography>

              {errors.map((error) => (
                <Box
                  key={error.code}
                  component="li"
                  sx={{ typography: 'caption' }}
                >
                  {error.message}
                </Box>
              ))}
            </Box>
          )
        })}
      </Paper>
    )
  else
    return (
      <Paper
        variant="outlined"
        sx={{
          py: 1.5,
          px: 2,
          mt: 3,
          borderColor: 'error.light',
          bgcolor: '#FFE7D9',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Iconify
            icon="bxs:error-circle"
            sx={{
              marginBottom: '-2px',
              marginRight: '10px',
              color: '#FF4842',
            }}
          />
          {renderErrors()}
        </Box>
      </Paper>
    )
}
