export const ellipsify = (text: string, length: number) => {
  if (text && text.length > length) {
    return `${text.slice(0, length)}...`
  }
  return text
}

export const toHHMMSS = (
  duration: number,
  includeSeconds = false,
) => {
  var hours = Math.floor(duration / 3600)
  var minutes = Math.floor((duration - hours * 3600) / 60)
  var seconds = duration - hours * 3600 - minutes * 60

  return includeSeconds
    ? `${hours} Jam ${minutes} Menit ${seconds} Detik `
    : `${hours} Jam ${minutes} Menit`
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = [
    'Bytes',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB',
  ]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${
    sizes[i]
  }`
}

export const stripHTMLTags = (text: string) => {
  return text ? text.replace(/(<([^>]+)>)/gi, '').trim() : ''
}
