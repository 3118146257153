import { ReactNode, useState, useEffect, useRef } from 'react'
import { ReactQuillProps } from 'react-quill'
// next
import dynamic from 'next/dynamic'
// @mui
import { styled } from '@mui/material/styles'
import { Stack, Box, BoxProps, Typography } from '@mui/material'
//
import EditorToolbar, { formats } from './EditorToolbar'
import { stripHTMLTags } from 'helpers/formats/string'

const ReactQuill = dynamic(
  async () => {
    const { default: RQ } = await import('react-quill')
    // eslint-disable-next-line react/display-name
    return ({ forwardedRef, ...props }) => (
      <RQ ref={forwardedRef} {...props} />
    )
  },
  {
    ssr: false,
    loading: () => (
      <Box
        sx={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
          bgcolor: 'background.paper',
        }}
      >
        Loading...
      </Box>
    ),
  },
)

ReactQuill.displayName = 'ReactQuill'

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.grey[500_32]}`,
  '& .ql-container.ql-snow': {
    borderColor: 'transparent',
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily,
  },
  '& .ql-editor': {
    minHeight: 200,
    maxHeight: 640,
    '&.ql-blank::before': {
      fontStyle: 'normal',
      color: theme.palette.text.disabled,
    },
    '& pre.ql-syntax': {
      ...theme.typography.body2,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[900],
    },
  },
}))

const InputLimit = styled(Typography)({
  fontSize: '12px',
  fontWeight: '400',
  color: '#8D8A9C',
})

// ----------------------------------------------------------------------

export interface Props extends ReactQuillProps {
  id?: string
  limit?: number
  error?: boolean
  simple?: boolean
  helperText?: ReactNode
  sx?: BoxProps
  disableFormats?: string[]
}

export default function Editor({
  id = 'minimal-quill',
  limit,
  error,
  value,
  onChange,
  simple = false,
  helperText,
  placeholder,
  disableFormats,
  sx,
  ...other
}: Props) {
  const quill = useRef(null)
  const [body, setBody] = useState('')
  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: false,
    clipboard: {
      matchVisual: false,
    },
  }
  const handleChange = (value) => {
    setBody(stripHTMLTags(value))
    onChange(value)
  }

  useEffect(() => {
    const initEditor = () => {
      if (quill.current) {
        setBody(stripHTMLTags(value))
        return
      }
      setTimeout(initEditor, 200)
    }
    initEditor()
  }, [quill])

  return (
    <div>
      <Stack direction="row" justifyContent="space-between">
        <EditorToolbar
          id={id}
          isSimple={simple}
          disableFormats={disableFormats}
        />
        <InputLimit sx={{ paddingTop: '8px' }}>{`${
          body.length < 0 ? 0 : body.length
        }/${limit}`}</InputLimit>
      </Stack>
      <RootStyle
        sx={{
          ...(error && {
            border: (theme) =>
              `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
        }}
      >
        <ReactQuill
          forwardedRef={quill}
          value={value}
          onChange={handleChange}
          modules={modules}
          formats={formats.filter(
            (format) => !disableFormats?.includes(format),
          )}
          placeholder={placeholder}
          {...other}
        />
      </RootStyle>

      {helperText && helperText}
    </div>
  )
}
