import React, { useEffect, useRef, useState } from 'react'
import { getName, getNames, getCode } from 'country-list'
import { useRouter } from 'next/router'
import {
  Button,
  Drawer,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useSupportedCountry } from 'domains/Users/Auth/hooks/useSupportedCountry'
import Flag from 'react-country-flag'
import SearchIcon from '@mui/icons-material/Search'
import {
  useAuthStore,
  useSupportedCountryStore,
} from 'domains/Users/Auth/store'
import StateImage from 'components/state-image'
import CloseIcon from '@mui/icons-material/Close'
import useLocales from 'hooks/useLocales'

export const StyledDesktopContainer = styled(Stack)({
  position: 'fixed',
  width: '300px',
  zIndex: 1,
  padding: '8px',
  height: 312,
  borderRadius: `12px`,
  background: `#FFFFFF`,
  boxShadow: `0px 0px 8px 0px rgba(145, 158, 171, 0.32)`,
})

export const StyledSearchContainer = styled(TextField)({
  width: '100%',
  display: 'flex',
  height: '44px',
  borderRadius: `8px`,
  background: 'white',
  border: `1px solid var(--14-others-button-input, rgba(145, 158, 171, 0.32))`,
  '& .MuiOutlinedInput-root': {
    height: '44px',
    '& fieldset': {
      border: 'unset',
    },
    '&:hover fieldset': {
      border: 'unset',
    },
    '&.Mui-focused fieldset': {
      border: 'unset',
    },
  },
  input: {
    fontSize: '14px',
  },
})

export const StyledHead = styled(Stack)({
  padding: '16px',
  justifyContent: `space-between`,
  alignItems: `center`,
})

interface ISelectCountryCodes {
  onChanged?: (countryData: any) => void
  value?: string
  readOnly?: boolean
  isHideFlag?: boolean
  isOpen?: boolean
  onClose?: Function
}

const CountryCode = ({
  onChanged,
  isOpen = false,
  onClose,
}: ISelectCountryCodes) => {
  const { isReady } = useRouter()
  const { t } = useLocales()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const [countriesData, setCountriesData] = useState([])

  const [searchTerm, setSearchTerm] = useState('')

  const { setCountryPhone, countryPhone } = useAuthStore((state) => ({
    setCountryPhone: state.setCountryPhone,
    countryPhone: state.countryPhone,
  }))

  const { isError } = useSupportedCountryStore((state) => ({
    isError: state.isError,
  }))
  const dialogRef = useRef(null)

  const { refetch: getSupportedCountry, data } = useSupportedCountry()

  const handleSelect = (country) => {
    setCountryPhone(country)
    onClose()
    if (onChanged) {
      onChanged(country)
    }
  }

  const filteredOptions = countriesData?.filter(
    (country) =>
      country.dial_code.includes(searchTerm.toLowerCase()) ||
      country?.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      country.country_code
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
  )

  const getNewArrayMapping = () => {
    const names = getNames()
    const countries = names.map((name) => ({
      name,
      country_code: getCode(name),
    }))
    const newArray = data.map((item1) => {
      const item2 = countries.find(
        (item2) => item2.country_code === item1.country_code,
      )
      return { ...item1, ...item2 }
    })
    setCountriesData(newArray)
  }

  const renderCountryList = () => {
    return (
      <>
        {filteredOptions.map((item) => (
          <Stack
            key={`country-code-container-${item.country_code}`}
            onClick={() => handleSelect(item)}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              color: 'black',
              padding: '13px 12px',
              gap: `8px`,
              borderRadius: `8px`,
              justifyContent: 'space-between',
              background:
                countryPhone.country_code === item.country_code &&
                `#8d8a9c29`,
              '&:hover': {
                background: `#8d8a9c29`,
              },
            }}
            direction={`row`}
          >
            <Stack direction={'row'}>
              <Flag
                countryCode={item.country_code}
                svg
                style={{
                  width: 24,
                  marginRight: 10,
                  marginTop: 3,
                }}
              />
              <Typography>{getName(item.country_code)}</Typography>
            </Stack>
            <Typography>{item.dial_code}</Typography>
          </Stack>
        ))}
      </>
    )
  }

  const renderEmpty = () => {
    return (
      <Stack
        sx={{
          alignItems: 'center',
          gap: '8px',
          height: !isDesktop && '85vh',
          margin: !isDesktop ? '20% auto' : '5% auto',
        }}
      >
        <StateImage
          type="error"
          sx={{
            width: isDesktop ? '180px' : '250px',
            height: 'auto',
          }}
        />
        <Typography variant={isDesktop ? 'h6' : 'h4'}>
          {t('key_country_code_not_found')}
        </Typography>
        <Typography
          sx={{
            fontSize: `14px`,
            fontWeight: `400`,
          }}
        >
          {t('key_try_again_with_another_keyword')}
        </Typography>
      </Stack>
    )
  }

  const renderError = () => {
    return (
      <Stack
        sx={{
          alignItems: 'center',
          gap: '8px',
          height: !isDesktop && '85vh',
          margin: !isDesktop ? '20% auto' : '5% auto',
        }}
      >
        <StateImage
          type="error"
          sx={{
            width: '200px',
            height: 'auto',
          }}
        />
        <Typography
          sx={{
            fontSize: `18px`,
            fontWeight: `800`,
          }}
        >
          Terjadi Kesalahan
        </Typography>
        <Typography
          sx={{
            fontSize: `14px`,
            fontWeight: `400`,
          }}
        >
          Silahkan coba kembali
        </Typography>
        <Button
          variant="contained"
          onClick={() => getSupportedCountry()}
        >
          Coba Lagi
        </Button>
      </Stack>
    )
  }

  const renderContent = () => {
    return (
      <>
        <StyledSearchContainer
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          variant="outlined"
          placeholder={t('key_type_the_country_name')}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ width: 24, height: 24 }}
              >
                <SearchIcon color={`inherit`} />
              </InputAdornment>
            ),
          }}
        />
        <Stack
          sx={{
            maxHeight: isDesktop ? '26vh' : '85vh',
            overflowY: 'auto',
            gap: '8px',
            marginTop: `16px`,
          }}
        >
          {!isError
            ? filteredOptions.length > 0
              ? renderCountryList()
              : renderEmpty()
            : renderError()}
        </Stack>
      </>
    )
  }

  const renderDesktopView = () => {
    return (
      <StyledDesktopContainer>
        {renderContent()}
      </StyledDesktopContainer>
    )
  }

  const renderMobileView = () => {
    return (
      <Drawer open={isOpen} onClose={() => onClose()} anchor="bottom">
        <StyledHead direction={'row'}>
          <Typography variant="h4">Cari Kode Negaramu</Typography>
          <CloseIcon
            sx={{ color: theme.palette.primary.main }}
            onClick={() => onClose()}
          />
        </StyledHead>
        <Stack sx={{ padding: '16px' }}>{renderContent()}</Stack>
      </Drawer>
    )
  }

  useEffect(() => {
    if (isReady) {
      getSupportedCountry()
    }
  }, [isReady])

  useEffect(() => {
    if (isReady && data?.length > 0) {
      getNewArrayMapping()
    }
  }, [isReady, data])

  return (
    <>
      {isOpen && (
        <Stack ref={dialogRef} sx={{ marginTop: '0px !important' }}>
          {isDesktop ? renderDesktopView() : renderMobileView()}
        </Stack>
      )}
    </>
  )
}

export default CountryCode
