import * as Yup from 'yup'
import { SyntheticEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Box,
  Tab,
  Typography,
} from '@mui/material'
import {
  LoadingButton,
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab'
import useAuth from 'hooks/useAuth'
import Iconify from 'components/Iconify'
import { FormProvider, RHFTextField } from 'components/hook-form'
import { useAuthStore } from '../store'
import { DeliveryMethod } from '../store/Auth/interface'
import { renderErrorMessage } from 'helpers/auth/error-auth'
import { useRouter } from 'next/router'
import trackEvent from '~/trackers'
import sendLog from 'helpers/send-log'
import Flag from 'react-country-flag'
import CountryCode from 'commons/components/CountryCode'
import useLocales from 'hooks/useLocales'

type FormPhoneValuesProps = {
  phoneNumber: string
  afterSubmit?: string
}

type FormEmailValuesProps = {
  email: string
  password: string
  afterSubmit?: string
}

const TAB_PANELS = [
  { label: 'key_phone_number', value: 'phone_number' },
  { label: 'key_email', value: 'email' },
]

export default function LoginForm() {
  const { t } = useLocales()
  const { query, pathname } = useRouter()
  const trackerData = { query, pathname }

  const { login, loginPhone } = useAuth()

  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenCountryCode, setOpenCountryCode] = useState(false)
  const [countryData, setCountryData] = useState({
    name: 'Indonesia',
    country_code: 'ID',
    dial_code: '+62',
    allow_sms: true,
  })

  // Email & password validations
  const defaultValues: FormEmailValuesProps = {
    email: '',
    password: '',
  }

  const LoginEmailSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('key_enter_a_valid_email'))
      .required(t('key_email_cannot_be_empty'))
      .label(t('key_email')),
    password: Yup.string()
      .required(t('key_password_cannot_be_empty'))
      .label('Password'),
  })

  const methodsEmail = useForm<FormEmailValuesProps>({
    resolver: yupResolver(LoginEmailSchema),
    defaultValues,
    mode: 'onChange',
  })

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = methodsEmail

  // Phone validations
  const LoginPhoneSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .min(6)
      .max(countryData.dial_code === '+62' ? 13 : 15)
      .matches(/^[0-9]+$/, t('key_enter_a_valid_cellphone_number'))
      .required(t('key_the_cellphone_number_cannot_be_empty'))
      .label('Phone Number'),
  })

  const methodsPhone = useForm<FormPhoneValuesProps>({
    resolver: yupResolver(LoginPhoneSchema),
    mode: 'onChange',
  })

  const {
    setError: setErrorPhone,
    handleSubmit: handleSubmitPhone,
    formState: {
      errors: errorsPhone,
      isSubmitting: isSubmittingPhone,
      isDirty: isDirtyPhone,
      isValid: isValidPhone,
    },
  } = methodsPhone

  const {
    setInputPhoneNumber,
    setVerificationMethod,
    setAuthTab,
    authTab,
  } = useAuthStore((state) => ({
    setInputPhoneNumber: state.setInputPhoneNumber,
    setVerificationMethod: state.setVerificationMethod,
    setAuthTab: state.setAuthTab,
    authTab: state.authTab,
  }))

  // Submit email & password
  const onSubmitEmail = async (data: FormEmailValuesProps) => {
    try {
      setIsLoading(true)
      await login(data.email, data.password)
    } catch (error) {
      sendLog(error)
      const errorMessage = renderErrorMessage(error?.code, {
        seconds: error.data?.retry_in_secs,
        defaultMessage: error.message,
        isLogin: true,
      })
      setIsLoading(false)

      setError('afterSubmit', {
        ...error,
        message: errorMessage,
      })
    }
  }

  // Submit phone number
  const onSubmitPhone = async (data: FormPhoneValuesProps) => {
    setInputPhoneNumber(data.phoneNumber)
    onHandleSetVerificationMethod('WA', data.phoneNumber)
  }

  const onHandleSetVerificationMethod = async (
    method: DeliveryMethod,
    phoneNumber: string,
  ) => {
    trackEvent.auth(
      `click_verification_method_${method.toLowerCase()}`,
      { ...trackerData, currentTab: authTab },
      { modal_name: 'CHOOSE_PHONE_NUMBER_VERIFICATION' },
    )
    if (!isLoading) {
      setVerificationMethod(method)
      setIsLoading(true)
      try {
        await loginPhone(phoneNumber, method)
      } catch (error) {
        setIsLoading(false)

        const errorMessage = renderErrorMessage(error?.code, {
          seconds: error.data?.retry_in_secs,
          defaultMessage: error.message,
          isLogin: true,
        })
        setErrorPhone('afterSubmit', {
          ...error,
          message: errorMessage,
        })
      }
    }
  }

  const handleChangeTab = (
    _event: SyntheticEvent<Element, Event>,
    activeTab: 'phone_number' | 'email',
  ) => {
    trackEvent.auth(
      activeTab === 'phone_number'
        ? 'select_phone_sign_in_tab'
        : 'select_email_sign_in_tab',
      {
        ...trackerData,
        currentTab: activeTab,
      },
    )
    setAuthTab(activeTab)
  }

  return (
    <TabContext value={authTab}>
      <TabList onChange={handleChangeTab}>
        {TAB_PANELS.map((tab, index) => (
          <Tab
            key={index}
            label={t(tab.label)}
            value={tab.value.toString()}
          />
        ))}
      </TabList>
      <Box
        sx={{
          mt: 2,
          width: '100%',
        }}
      >
        <TabPanel value="phone_number">
          <FormProvider
            methods={methodsPhone}
            onSubmit={handleSubmitPhone(onSubmitPhone)}
          >
            <Stack spacing={3}>
              <Stack spacing={3}>
                {!!errorsPhone.afterSubmit && (
                  <Alert severity="error">
                    {errorsPhone.afterSubmit.message}
                  </Alert>
                )}
                <RHFTextField
                  name="phoneNumber"
                  label={t('key_phone_number')}
                  placeholder={t('key_enter_your_phone_number')}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        onClick={() => {
                          setOpenCountryCode(!isOpenCountryCode)
                        }}
                        sx={{
                          height: '56px',
                          maxHeight: 'unset',
                        }}
                      >
                        <Flag
                          countryCode={countryData.country_code}
                          svg
                          style={{
                            width: 24,
                            marginRight: 10,
                            marginTop: 3,
                          }}
                        />
                        <Typography>
                          {countryData.dial_code}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '.MuiInputBase-root': {
                      gap: '16px',
                    },
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.key === 'ArrowUp' ||
                      e.key === 'ArrowDown'
                    ) {
                      e.preventDefault()
                    }
                  }}
                  onWheel={(e) => (e.target as HTMLElement).blur()}
                />
                <CountryCode
                  isOpen={isOpenCountryCode}
                  onClose={() => {
                    setOpenCountryCode(false)
                  }}
                  onChanged={(data) => {
                    setCountryData(data)
                  }}
                />
              </Stack>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmittingPhone}
                disabled={!isValidPhone || !isDirtyPhone}
              >
                {t('key_continue')}
              </LoadingButton>
            </Stack>
          </FormProvider>
        </TabPanel>
        <TabPanel value="email">
          <FormProvider
            methods={methodsEmail}
            onSubmit={handleSubmit(onSubmitEmail)}
          >
            <Stack spacing={3}>
              <Stack spacing={3}>
                {!!errors.afterSubmit && (
                  <Alert severity="error">
                    {errors.afterSubmit.message}
                  </Alert>
                )}

                <RHFTextField name="email" label="Email address" />

                <RHFTextField
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowPassword(!showPassword)
                          }
                          edge="end"
                        >
                          <Iconify
                            icon={
                              showPassword
                                ? 'eva:eye-fill'
                                : 'eva:eye-off-fill'
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!isDirty || !isValid}
              >
                {t('key_continue')}
              </LoadingButton>
            </Stack>
          </FormProvider>
        </TabPanel>
      </Box>
    </TabContext>
  )
}
