import { MouseEventHandler } from 'react'
import { Button, Divider, Grid, Typography } from '@mui/material'
import Iconify from 'components/Iconify'
import useAuth from 'hooks/useAuth'
import { useRouter } from 'next/router'
import trackEvent from '~/trackers'
import { useAuthStore } from '../store'
import useLocales from 'hooks/useLocales'

export default function LoginSocial() {
  const { t } = useLocales()
  const { query, pathname } = useRouter()
  const trackerData = { query, pathname }

  const { loginSocial } = useAuth()
  const { authTab } = useAuthStore((state) => ({
    authTab: state.authTab,
  }))

  const handleSignInWithGoogle: MouseEventHandler = async (e) => {
    trackEvent.auth('click_google_sign_in', {
      ...trackerData,
      currentTab: authTab,
    })
    e.preventDefault()
    loginSocial('google')
  }

  return (
    <>
      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {t('key_or')}
        </Typography>
      </Divider>
      <Grid container spacing={2}>
        <Grid item xs>
          <Button
            fullWidth
            size="large"
            color="inherit"
            variant="outlined"
            onClick={handleSignInWithGoogle}
          >
            <Iconify
              icon={'flat-color-icons:google'}
              color="#DF3E30"
              width={24}
              height={24}
            />
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
