// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { FormHelperText } from '@mui/material'
//
import Iconify from 'components/Iconify'
import Editor, { Props as EditorProps } from '../editor'
import useLocales from 'hooks/useLocales'

// ----------------------------------------------------------------------

interface Props extends EditorProps {
  name: string
  disabled?: boolean
}

export default function RHFEditor({
  name,
  limit,
  disabled = false,
  ...other
}: Props) {
  const { t } = useLocales()
  const { control } = useFormContext()

  const renderErrorText = (message: string | undefined) => {
    if (message)
      return (
        <>
          <Iconify
            icon="bxs:error-circle"
            sx={{ marginBottom: '-2px', marginRight: '5px' }}
          />
          {t(message)}
        </>
      )
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Editor
          id={name}
          value={field.value}
          limit={limit}
          onChange={field.onChange}
          onBlur={field.onBlur}
          error={!!error}
          helperText={
            <FormHelperText
              error
              sx={{ px: 2, textTransform: 'capitalize' }}
            >
              {renderErrorText(error?.message)}
            </FormHelperText>
          }
          readOnly={disabled}
          {...other}
        />
      )}
    />
  )
}
